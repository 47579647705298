<template>
  <v-container>
    <v-row justify="end" class="mt-1">
      <v-col cols="12" md="6" class="text-right">
        <v-btn
          small
          outlined
          class="mr-3"
          @click="editService($route.params.id)"
          :color="$store.state.primaryColor"
        >
          <v-icon left small>edit</v-icon>
          <span class="caption">{{ $t("edit details") }}</span>
        </v-btn>
        <v-btn
          small
          outlined
          class="mr-3"
          @click="delDialog = true"
          :color="$store.state.secondaryColor"
        >
          <v-icon left small>delete</v-icon>
          <span class="caption">{{ $t("delete") }}</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <!-- <v-icon>delete</v-icon> -->
          <p>{{ $t("are you sure") }}?</p>
          <p>{{ $t("do you really want to delete this service") }}?</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            small
            @click="deleteService"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("deleted") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackbar = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-row v-if="service">
      <v-col cols="12">
        <v-list dense class="elevation-1">
          <v-list-item class="caption d-flex flex-wrap">
            <v-col cols="12" md="4">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("service name") }} :
              </span>
              <span class="">{{ service.name }} </span>
            </v-col>

            <v-col cols="12" md="4">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("category") }} :
              </span>
              <span class="">{{ service.category }} </span>
            </v-col>
            <v-col cols="12" md="4">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("description") }} :
              </span>
              <span class="">{{ service.description }} </span>
            </v-col>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item class="caption d-flex flex-wrap">
            <v-col cols="12" md="4">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("selling price") }} :
              </span>
              <span class="">{{ service.price.selling }} </span>
            </v-col>
            <v-col cols="12" md="4">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("limit price") }} :
              </span>
              <span class="">{{ service.price.limit }} </span>
            </v-col>
            <v-col cols="12" md="4">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("discount price") }} :
              </span>
              <span class="">{{ service.price.discount }} </span>
            </v-col>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item class="caption d-flex flex-wrap">
            <v-col cols="12" md="4">
              <span class="font-weight-medium text-capitalize"
                >{{ $t("created on") }} :
              </span>
              <span class="">{{ formatDate(service.dateCreated) }} </span>
            </v-col>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
    <div v-show="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
  </v-container>
</template>

<script>
import { format } from "date-fns";
import db from "@/plugins/fb";

import PleaseWait from "@/components/templates/PleaseWait";
export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    dialog: false,
    service: "",
    e1: 1,
    snackbar: false,
    timeout: 2000,
    pleaseWait: true,
    delDialog: false,
    loading: false,
  }),

  mounted() {
    this.getService();
  },

  methods: {
    getService() {
      db.collection("services")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.service = doc.data();
          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
        });
    },

    editService(prodID) {
      this.$router.push({ name: "EditService", params: { id: prodID } });
    },

    deleteService() {
      db.collection("services")
        .doc(this.$route.params.id)
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          this.snackbar = true;
          setTimeout(() => this.goBack(), 2000);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },

    goBack() {
      this.$router.push("/services");
    },

    formatDate(val) {
      var date = val.toDate();
      return format(date, "yyyy-MM-dd : HH:MM:SS");
    },
  },
};
</script>

<style></style>
